import { FileHandlersMixin, FORMAT_DATE_SECONDARY, FORMAT_DATE_PRIMARY, EDIT_PERMISSION, MAINTAIN_PERMISSION, FILTERS_EQUAL, FILTERS_CONTAINS, FILTERS_GREATER_THAN_OR_EQUAL, FILTERS_LESS_THAN_OR_EQUAL, VIEW_PERMISSION, EXO_DATA } from "@kraftheinz/common";
import { D as DATA_SOURCE_ID } from "./index8.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var queryString = {};
var strictUriEncode = (str) => encodeURIComponent(str).replace(/[!'()*]/g, (x) => `%${x.charCodeAt(0).toString(16).toUpperCase()}`);
var token = "%[a-f0-9]{2}";
var singleMatcher = new RegExp(token, "gi");
var multiMatcher = new RegExp("(" + token + ")+", "gi");
function decodeComponents(components, split) {
  try {
    return decodeURIComponent(components.join(""));
  } catch (err) {
  }
  if (components.length === 1) {
    return components;
  }
  split = split || 1;
  var left = components.slice(0, split);
  var right = components.slice(split);
  return Array.prototype.concat.call([], decodeComponents(left), decodeComponents(right));
}
function decode(input) {
  try {
    return decodeURIComponent(input);
  } catch (err) {
    var tokens = input.match(singleMatcher);
    for (var i = 1; i < tokens.length; i++) {
      input = decodeComponents(tokens, i).join("");
      tokens = input.match(singleMatcher);
    }
    return input;
  }
}
function customDecodeURIComponent(input) {
  var replaceMap = {
    "%FE%FF": "\uFFFD\uFFFD",
    "%FF%FE": "\uFFFD\uFFFD"
  };
  var match = multiMatcher.exec(input);
  while (match) {
    try {
      replaceMap[match[0]] = decodeURIComponent(match[0]);
    } catch (err) {
      var result = decode(match[0]);
      if (result !== match[0]) {
        replaceMap[match[0]] = result;
      }
    }
    match = multiMatcher.exec(input);
  }
  replaceMap["%C2"] = "\uFFFD";
  var entries = Object.keys(replaceMap);
  for (var i = 0; i < entries.length; i++) {
    var key = entries[i];
    input = input.replace(new RegExp(key, "g"), replaceMap[key]);
  }
  return input;
}
var decodeUriComponent = function(encodedURI) {
  if (typeof encodedURI !== "string") {
    throw new TypeError("Expected `encodedURI` to be of type `string`, got `" + typeof encodedURI + "`");
  }
  try {
    encodedURI = encodedURI.replace(/\+/g, " ");
    return decodeURIComponent(encodedURI);
  } catch (err) {
    return customDecodeURIComponent(encodedURI);
  }
};
var splitOnFirst = (string, separator) => {
  if (!(typeof string === "string" && typeof separator === "string")) {
    throw new TypeError("Expected the arguments to be of type `string`");
  }
  if (separator === "") {
    return [string];
  }
  const separatorIndex = string.indexOf(separator);
  if (separatorIndex === -1) {
    return [string];
  }
  return [
    string.slice(0, separatorIndex),
    string.slice(separatorIndex + separator.length)
  ];
};
var filterObj = function(obj, predicate) {
  var ret = {};
  var keys = Object.keys(obj);
  var isArr = Array.isArray(predicate);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    var val = obj[key];
    if (isArr ? predicate.indexOf(key) !== -1 : predicate(key, val, obj)) {
      ret[key] = val;
    }
  }
  return ret;
};
(function(exports) {
  const strictUriEncode$1 = strictUriEncode;
  const decodeComponent = decodeUriComponent;
  const splitOnFirst$1 = splitOnFirst;
  const filterObject = filterObj;
  const isNullOrUndefined = (value) => value === null || value === void 0;
  const encodeFragmentIdentifier = Symbol("encodeFragmentIdentifier");
  function encoderForArrayFormat(options) {
    switch (options.arrayFormat) {
      case "index":
        return (key) => (result, value) => {
          const index2 = result.length;
          if (value === void 0 || options.skipNull && value === null || options.skipEmptyString && value === "") {
            return result;
          }
          if (value === null) {
            return [...result, [encode(key, options), "[", index2, "]"].join("")];
          }
          return [
            ...result,
            [encode(key, options), "[", encode(index2, options), "]=", encode(value, options)].join("")
          ];
        };
      case "bracket":
        return (key) => (result, value) => {
          if (value === void 0 || options.skipNull && value === null || options.skipEmptyString && value === "") {
            return result;
          }
          if (value === null) {
            return [...result, [encode(key, options), "[]"].join("")];
          }
          return [...result, [encode(key, options), "[]=", encode(value, options)].join("")];
        };
      case "colon-list-separator":
        return (key) => (result, value) => {
          if (value === void 0 || options.skipNull && value === null || options.skipEmptyString && value === "") {
            return result;
          }
          if (value === null) {
            return [...result, [encode(key, options), ":list="].join("")];
          }
          return [...result, [encode(key, options), ":list=", encode(value, options)].join("")];
        };
      case "comma":
      case "separator":
      case "bracket-separator": {
        const keyValueSep = options.arrayFormat === "bracket-separator" ? "[]=" : "=";
        return (key) => (result, value) => {
          if (value === void 0 || options.skipNull && value === null || options.skipEmptyString && value === "") {
            return result;
          }
          value = value === null ? "" : value;
          if (result.length === 0) {
            return [[encode(key, options), keyValueSep, encode(value, options)].join("")];
          }
          return [[result, encode(value, options)].join(options.arrayFormatSeparator)];
        };
      }
      default:
        return (key) => (result, value) => {
          if (value === void 0 || options.skipNull && value === null || options.skipEmptyString && value === "") {
            return result;
          }
          if (value === null) {
            return [...result, encode(key, options)];
          }
          return [...result, [encode(key, options), "=", encode(value, options)].join("")];
        };
    }
  }
  function parserForArrayFormat(options) {
    let result;
    switch (options.arrayFormat) {
      case "index":
        return (key, value, accumulator) => {
          result = /\[(\d*)\]$/.exec(key);
          key = key.replace(/\[\d*\]$/, "");
          if (!result) {
            accumulator[key] = value;
            return;
          }
          if (accumulator[key] === void 0) {
            accumulator[key] = {};
          }
          accumulator[key][result[1]] = value;
        };
      case "bracket":
        return (key, value, accumulator) => {
          result = /(\[\])$/.exec(key);
          key = key.replace(/\[\]$/, "");
          if (!result) {
            accumulator[key] = value;
            return;
          }
          if (accumulator[key] === void 0) {
            accumulator[key] = [value];
            return;
          }
          accumulator[key] = [].concat(accumulator[key], value);
        };
      case "colon-list-separator":
        return (key, value, accumulator) => {
          result = /(:list)$/.exec(key);
          key = key.replace(/:list$/, "");
          if (!result) {
            accumulator[key] = value;
            return;
          }
          if (accumulator[key] === void 0) {
            accumulator[key] = [value];
            return;
          }
          accumulator[key] = [].concat(accumulator[key], value);
        };
      case "comma":
      case "separator":
        return (key, value, accumulator) => {
          const isArray = typeof value === "string" && value.includes(options.arrayFormatSeparator);
          const isEncodedArray = typeof value === "string" && !isArray && decode2(value, options).includes(options.arrayFormatSeparator);
          value = isEncodedArray ? decode2(value, options) : value;
          const newValue = isArray || isEncodedArray ? value.split(options.arrayFormatSeparator).map((item) => decode2(item, options)) : value === null ? value : decode2(value, options);
          accumulator[key] = newValue;
        };
      case "bracket-separator":
        return (key, value, accumulator) => {
          const isArray = /(\[\])$/.test(key);
          key = key.replace(/\[\]$/, "");
          if (!isArray) {
            accumulator[key] = value ? decode2(value, options) : value;
            return;
          }
          const arrayValue = value === null ? [] : value.split(options.arrayFormatSeparator).map((item) => decode2(item, options));
          if (accumulator[key] === void 0) {
            accumulator[key] = arrayValue;
            return;
          }
          accumulator[key] = [].concat(accumulator[key], arrayValue);
        };
      default:
        return (key, value, accumulator) => {
          if (accumulator[key] === void 0) {
            accumulator[key] = value;
            return;
          }
          accumulator[key] = [].concat(accumulator[key], value);
        };
    }
  }
  function validateArrayFormatSeparator(value) {
    if (typeof value !== "string" || value.length !== 1) {
      throw new TypeError("arrayFormatSeparator must be single character string");
    }
  }
  function encode(value, options) {
    if (options.encode) {
      return options.strict ? strictUriEncode$1(value) : encodeURIComponent(value);
    }
    return value;
  }
  function decode2(value, options) {
    if (options.decode) {
      return decodeComponent(value);
    }
    return value;
  }
  function keysSorter(input) {
    if (Array.isArray(input)) {
      return input.sort();
    }
    if (typeof input === "object") {
      return keysSorter(Object.keys(input)).sort((a, b) => Number(a) - Number(b)).map((key) => input[key]);
    }
    return input;
  }
  function removeHash(input) {
    const hashStart = input.indexOf("#");
    if (hashStart !== -1) {
      input = input.slice(0, hashStart);
    }
    return input;
  }
  function getHash(url) {
    let hash = "";
    const hashStart = url.indexOf("#");
    if (hashStart !== -1) {
      hash = url.slice(hashStart);
    }
    return hash;
  }
  function extract(input) {
    input = removeHash(input);
    const queryStart = input.indexOf("?");
    if (queryStart === -1) {
      return "";
    }
    return input.slice(queryStart + 1);
  }
  function parseValue(value, options) {
    if (options.parseNumbers && !Number.isNaN(Number(value)) && (typeof value === "string" && value.trim() !== "")) {
      value = Number(value);
    } else if (options.parseBooleans && value !== null && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
      value = value.toLowerCase() === "true";
    }
    return value;
  }
  function parse(query, options) {
    options = Object.assign({
      decode: true,
      sort: true,
      arrayFormat: "none",
      arrayFormatSeparator: ",",
      parseNumbers: false,
      parseBooleans: false
    }, options);
    validateArrayFormatSeparator(options.arrayFormatSeparator);
    const formatter = parserForArrayFormat(options);
    const ret = /* @__PURE__ */ Object.create(null);
    if (typeof query !== "string") {
      return ret;
    }
    query = query.trim().replace(/^[?#&]/, "");
    if (!query) {
      return ret;
    }
    for (const param of query.split("&")) {
      if (param === "") {
        continue;
      }
      let [key, value] = splitOnFirst$1(options.decode ? param.replace(/\+/g, " ") : param, "=");
      value = value === void 0 ? null : ["comma", "separator", "bracket-separator"].includes(options.arrayFormat) ? value : decode2(value, options);
      formatter(decode2(key, options), value, ret);
    }
    for (const key of Object.keys(ret)) {
      const value = ret[key];
      if (typeof value === "object" && value !== null) {
        for (const k of Object.keys(value)) {
          value[k] = parseValue(value[k], options);
        }
      } else {
        ret[key] = parseValue(value, options);
      }
    }
    if (options.sort === false) {
      return ret;
    }
    return (options.sort === true ? Object.keys(ret).sort() : Object.keys(ret).sort(options.sort)).reduce((result, key) => {
      const value = ret[key];
      if (Boolean(value) && typeof value === "object" && !Array.isArray(value)) {
        result[key] = keysSorter(value);
      } else {
        result[key] = value;
      }
      return result;
    }, /* @__PURE__ */ Object.create(null));
  }
  exports.extract = extract;
  exports.parse = parse;
  exports.stringify = (object, options) => {
    if (!object) {
      return "";
    }
    options = Object.assign({
      encode: true,
      strict: true,
      arrayFormat: "none",
      arrayFormatSeparator: ","
    }, options);
    validateArrayFormatSeparator(options.arrayFormatSeparator);
    const shouldFilter = (key) => options.skipNull && isNullOrUndefined(object[key]) || options.skipEmptyString && object[key] === "";
    const formatter = encoderForArrayFormat(options);
    const objectCopy = {};
    for (const key of Object.keys(object)) {
      if (!shouldFilter(key)) {
        objectCopy[key] = object[key];
      }
    }
    const keys = Object.keys(objectCopy);
    if (options.sort !== false) {
      keys.sort(options.sort);
    }
    return keys.map((key) => {
      const value = object[key];
      if (value === void 0) {
        return "";
      }
      if (value === null) {
        return encode(key, options);
      }
      if (Array.isArray(value)) {
        if (value.length === 0 && options.arrayFormat === "bracket-separator") {
          return encode(key, options) + "[]";
        }
        return value.reduce(formatter(key), []).join("&");
      }
      return encode(key, options) + "=" + encode(value, options);
    }).filter((x) => x.length > 0).join("&");
  };
  exports.parseUrl = (url, options) => {
    options = Object.assign({
      decode: true
    }, options);
    const [url_, hash] = splitOnFirst$1(url, "#");
    return Object.assign({
      url: url_.split("?")[0] || "",
      query: parse(extract(url), options)
    }, options && options.parseFragmentIdentifier && hash ? { fragmentIdentifier: decode2(hash, options) } : {});
  };
  exports.stringifyUrl = (object, options) => {
    options = Object.assign({
      encode: true,
      strict: true,
      [encodeFragmentIdentifier]: true
    }, options);
    const url = removeHash(object.url).split("?")[0] || "";
    const queryFromUrl = exports.extract(object.url);
    const parsedQueryFromUrl = exports.parse(queryFromUrl, { sort: false });
    const query = Object.assign(parsedQueryFromUrl, object.query);
    let queryString2 = exports.stringify(query, options);
    if (queryString2) {
      queryString2 = `?${queryString2}`;
    }
    let hash = getHash(object.url);
    if (object.fragmentIdentifier) {
      hash = `#${options[encodeFragmentIdentifier] ? encode(object.fragmentIdentifier, options) : object.fragmentIdentifier}`;
    }
    return `${url}${queryString2}${hash}`;
  };
  exports.pick = (input, filter, options) => {
    options = Object.assign({
      parseFragmentIdentifier: true,
      [encodeFragmentIdentifier]: false
    }, options);
    const { url, query, fragmentIdentifier } = exports.parseUrl(input, options);
    return exports.stringifyUrl({
      url,
      query: filterObject(query, filter),
      fragmentIdentifier
    }, options);
  };
  exports.exclude = (input, filter, options) => {
    const exclusionFilter = Array.isArray(filter) ? (key) => !filter.includes(key) : (key, value) => !filter(key, value);
    return exports.pick(input, exclusionFilter, options);
  };
})(queryString);
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Export Orders Data",
      "search-by": "CustName,Notes,Order,VesselVoyage,BookingNo",
      "create-button-text": "Update from JDE",
      "ph-search-by": "Search by Customer Name, Notes, Order, Vessel Voyage or Booking Number",
      "row-key": "id",
      "actions-width": 80,
      "dynamic-columns": true,
      "has-active": false,
      "scroll": {
        x: true,
        y: 500
      },
      "url-tracking-column": _vm.urlTrackingColumn,
      "tracking-columns": _vm.trackingColumns,
      "has-filter-inactive": false
    },
    scopedSlots: _vm._u([{
      key: "custom-buttons",
      fn: function() {
        return [_c("a-button", {
          attrs: {
            "loading": _vm.isExportingOrders,
            "type": "primary"
          },
          on: {
            "click": _vm.onExportOrdersClick
          }
        }, [_vm._v("Download Orders")])];
      },
      proxy: true
    }, {
      key: "customFilter",
      fn: function() {
        return [_c("a-space", {
          staticClass: "mr-2"
        }, [_c("div", {
          staticClass: "filter"
        }, [_c("select-filter", {
          key: "CountryCode",
          attrs: {
            "data-source": _vm.countries,
            "source": "countryCode",
            "source-label": "name",
            "placeholder": "Select Country",
            "value": _vm.valueCountry
          },
          on: {
            "change": function($event) {
              return _vm.onFilterChange("CountryCode", $event.value);
            }
          }
        })], 1), _c("div", {
          staticClass: "filter"
        }, [_c("select-filter-v-2", {
          key: "TypeData",
          attrs: {
            "reference": "export-order.common.clients",
            "source": "code",
            "source-label": "name",
            "has-option-all": true,
            "value": _vm.valueClient
          },
          on: {
            "change": function($event) {
              return _vm.onFilterChange("TypeData", $event.value);
            }
          }
        })], 1), _c("div", {
          staticClass: "filter__date-range"
        }, [_c("date-range-picker", {
          attrs: {
            "value": _vm.valueShipDate
          },
          on: {
            "change": function($event) {
              return _vm.onFilterChange("FromShipDate", $event);
            }
          }
        })], 1), _c("checkbox-input", {
          attrs: {
            "label": "Show at RISK records"
          },
          on: {
            "change": _vm.onShowRiskRecordsToggle
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var record = _ref.record;
        return [_vm.hasEditPermission ? [_c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "disabled": record.isInactive,
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.goToEdit(record);
            }
          }
        })], 1)] : _vm._e(), _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Detail")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          attrs: {
            "icon": "right-circle",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return _vm.goToDetail(record);
            }
          }
        })], 1)];
      }
    }])
  }, [_c("text-field", {
    key: "CountryCode",
    attrs: {
      "data-index": "countryCode",
      "title": "Country",
      "sorter": true,
      "checked": true,
      "width": 90
    }
  }), _c("text-field", {
    key: "Whs",
    attrs: {
      "data-index": "whs",
      "title": "Whs",
      "sorter": true,
      "checked": true,
      "width": 70
    }
  }), _c("text-field", {
    key: "Dvsn",
    attrs: {
      "data-index": "dvsn",
      "title": "Dvsn",
      "sorter": true,
      "checked": true,
      "width": 70
    }
  }), _c("text-field", {
    key: "Chnl",
    attrs: {
      "data-index": "chnl",
      "title": "Chnl",
      "sorter": true,
      "checked": true,
      "width": 175
    }
  }), _c("text-field", {
    key: "CustName",
    attrs: {
      "data-index": "custName",
      "title": "Cust Name",
      "sorter": true,
      "checked": true,
      "width": 200
    }
  }), _c("text-field", {
    key: "Order",
    attrs: {
      "data-index": "order",
      "title": "Order",
      "sorter": true,
      "checked": true,
      "width": 120
    }
  }), _c("text-field", {
    key: "brnd",
    attrs: {
      "data-index": "brnd",
      "title": "Brnd",
      "sorter": true,
      "checked": true,
      "width": 80
    }
  }), _c("text-field", {
    key: "CustPO",
    attrs: {
      "data-index": "custPO",
      "title": "Cust PO",
      "sorter": true,
      "checked": true,
      "width": 140
    }
  }), _c("text-field", {
    key: "InvShipDate",
    attrs: {
      "data-index": "invShipDate",
      "title": "Inv/Ship Date",
      "parse": _vm.parseDate,
      "checked": true,
      "width": 140,
      "sorter": true
    }
  }), _c("text-field", {
    key: "LoadNo",
    attrs: {
      "data-index": "loadNo",
      "title": "Load No",
      "sorter": true,
      "checked": true,
      "width": 120
    }
  }), _c("text-field", {
    key: "Stat",
    attrs: {
      "data-index": "stat",
      "title": "Stat",
      "sorter": true,
      "checked": true,
      "width": 90
    }
  }), _c("text-field", {
    key: "StatDescr",
    attrs: {
      "data-index": "statDescr",
      "title": "Stat Descr",
      "sorter": true,
      "checked": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "TrackingStatus",
    attrs: {
      "data-index": "trackingStatus",
      "title": "Tracking Status",
      "sorter": true,
      "checked": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "Owner",
    attrs: {
      "data-index": "owner",
      "title": "Owner",
      "sorter": true,
      "checked": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "EntryDate",
    attrs: {
      "data-index": "entryDate",
      "title": " Entry Date",
      "parse": _vm.parseDate,
      "sorter": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "ShortNote",
    attrs: {
      "data-index": "shortNote",
      "title": "Short Note",
      "sorter": true,
      "width": 150
    }
  }), _c("popover-field", {
    key: "Notes",
    attrs: {
      "data-index": "notes",
      "title": "Notes",
      "checked": true,
      "width": 350,
      "ellipsis": true
    }
  }), _c("popover-field", {
    key: "Gbs",
    attrs: {
      "data-index": "gbs",
      "title": "GBS Notes",
      "checked": true,
      "width": 350,
      "ellipsis": true
    }
  }), _c("text-field", {
    key: "Fcllcl",
    attrs: {
      "data-index": "fcllcl",
      "title": "FCL/LCL",
      "sorter": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "VesselVoyage",
    attrs: {
      "data-index": "vesselVoyage",
      "title": "Vessel Voyage",
      "sorter": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "BookingNo",
    attrs: {
      "data-index": "bookingNo",
      "title": "Booking Number",
      "sorter": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "EtdDate",
    attrs: {
      "data-index": "etdDate",
      "title": "ETD Date",
      "parse": _vm.parseDate,
      "sorter": true,
      "width": 150
    }
  }), _c("text-field", {
    key: "DocsSharedDate",
    attrs: {
      "data-index": "docsSharedDate",
      "title": "Docs Shared Date",
      "parse": _vm.parseDate,
      "width": 150
    }
  }), _c("text-field", {
    key: "Qty",
    attrs: {
      "data-index": "qty",
      "title": "Qty",
      "width": 100,
      "parse": _vm.parseNumber
    }
  }), _c("text-field", {
    key: "OrigETD",
    attrs: {
      "data-index": "origETD",
      "title": "Orig ETD",
      "width": 150,
      "parse": _vm.parseDate
    }
  }), _c("text-field", {
    key: "InvoiceDate",
    attrs: {
      "data-index": "invoiceDate",
      "title": "Invoice Date",
      "parse": _vm.parseDate,
      "width": 150
    }
  }), _c("text-field", {
    key: "VesselCutoffDate",
    attrs: {
      "data-index": "vesselCutoffDate",
      "title": "Vessel Cutoff Date",
      "parse": _vm.parseDate,
      "width": 150
    }
  }), _c("text-field", {
    key: "FinalDestinationPort",
    attrs: {
      "data-index": "finalDestinationPort",
      "title": "Final Destination Port",
      "width": 150
    }
  }), _c("text-field", {
    key: "GeneralDesc1",
    attrs: {
      "data-index": "generalDesc1",
      "title": "General Desc 1",
      "width": 200
    }
  }), _c("text-field", {
    key: "GeneralDesc2",
    attrs: {
      "data-index": "generalDesc2",
      "title": "General Desc 2",
      "width": 200
    }
  }), _c("text-field", {
    key: "GeneralDesc3",
    attrs: {
      "data-index": "generalDesc3",
      "title": "General Desc 3",
      "width": 200
    }
  }), _c("text-field", {
    key: "GeneralDesc4",
    attrs: {
      "data-index": "generalDesc4",
      "title": "General Desc 4",
      "width": 200
    }
  }), _c("text-field", {
    key: "GeneralDesc5",
    attrs: {
      "data-index": "generalDesc5",
      "title": "General Desc 5",
      "width": 200
    }
  }), _c("text-field", {
    key: "ShippingMarks1",
    attrs: {
      "data-index": "shippingMarks1",
      "title": "Shipping Marks 1",
      "width": 200
    }
  }), _c("text-field", {
    key: "ShippingMarks2",
    attrs: {
      "data-index": "shippingMarks2",
      "title": "Shipping Marks 2",
      "width": 200
    }
  }), _c("text-field", {
    key: "ShippingMarks3",
    attrs: {
      "data-index": "shippingMarks3",
      "title": "Shipping Marks 3",
      "width": 200
    }
  }), _c("text-field", {
    key: "ShippingMarks4",
    attrs: {
      "data-index": "shippingMarks4",
      "title": "Shipping Marks 4",
      "width": 200
    }
  }), _c("text-field", {
    key: "ShippingMarks5",
    attrs: {
      "data-index": "shippingMarks5",
      "title": "Shipping Marks 5",
      "width": 200
    }
  }), _c("text-field", {
    key: "SoldToCustomer",
    attrs: {
      "data-index": "soldToCustomer",
      "title": "Sold To Customer",
      "width": 150
    }
  }), _c("text-field", {
    key: "ShipToCustomer",
    attrs: {
      "data-index": "shipToCustomer",
      "title": "Ship To Customer",
      "width": 150
    }
  }), _c("text-field", {
    key: "ShipToNumber",
    attrs: {
      "data-index": "shipToNumber",
      "title": "Ship To Number",
      "width": 150
    }
  }), _c("text-field", {
    key: "PickDate",
    attrs: {
      "data-index": "pickDate",
      "title": "Pick Date",
      "parse": _vm.parseDate,
      "width": 150
    }
  }), _c("text-field", {
    key: "Carrier",
    attrs: {
      "data-index": "carrier",
      "title": "Carrier",
      "width": 150
    }
  }), _c("text-field", {
    key: "FreightTerms",
    attrs: {
      "data-index": "freightTerms",
      "title": "Freight Terms",
      "width": 150
    }
  }), _c("text-field", {
    key: "LoadNumber",
    attrs: {
      "data-index": "loadNumber",
      "title": "Load Number",
      "width": 150
    }
  }), _c("text-field", {
    key: "DateDocsDue",
    attrs: {
      "data-index": "dateDocsDue",
      "title": "Date Docs Due",
      "parse": _vm.parseDate,
      "width": 150
    }
  }), _c("text-field", {
    key: "Met",
    attrs: {
      "data-index": "met",
      "title": "Met / Not Met",
      "width": 150
    }
  }), _c("text-field", {
    key: "DelayedOrders",
    attrs: {
      "data-index": "delayedOrders",
      "title": "Delayed Orders",
      "width": 150
    }
  }), _c("text-field", {
    key: "LeadTime",
    attrs: {
      "data-index": "leadTime",
      "title": "Lead Time",
      "width": 150
    }
  }), _c("text-field", {
    key: "Category",
    attrs: {
      "data-index": "category",
      "parse": _vm.parseCurrency,
      "title": "Category",
      "width": 150
    }
  }), _c("text-field", {
    key: "ValueEst",
    attrs: {
      "data-index": "valueEst",
      "parse": _vm.parseCurrency,
      "title": "Value Est",
      "width": 150
    }
  }), _c("text-field", {
    key: "InvVal",
    attrs: {
      "data-index": "invVal",
      "title": "Inv Val",
      "width": 150
    }
  })], 1);
};
var staticRenderFns$1 = [];
var ListExportOrdersData_vue_vue_type_style_index_0_scoped_true_lang = "";
const currencyFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "USD",
  currencyDisplay: "narrowSymbol",
  maximumFractionDigits: 0
});
const __vue2_script$1 = {
  name: "ListExportOrdersData",
  inject: ["apiUrl", "crud", "can"],
  mixins: [FileHandlersMixin],
  data() {
    const countries = [
      {
        id: 0,
        name: "All",
        countryCode: "AU,NZ"
      }
    ];
    const defaultFromShipDate = this.$moment().subtract(30, "days").format(FORMAT_DATE_SECONDARY);
    return {
      DATA_SOURCE_ID,
      FORMAT_DATE_PRIMARY,
      countries,
      defaultFromShipDate,
      isExportingOrders: false,
      urlTrackingColumn: `${this.apiUrl}/export-order/common/tracking-column`,
      trackingColumns: "exportOrderColumnNames"
    };
  },
  computed: {
    hasEditPermission() {
      return this.can(EDIT_PERMISSION) || this.can(MAINTAIN_PERMISSION);
    },
    filterCountry() {
      return this.crud.getFilter("CountryCode");
    },
    valueCountry() {
      if (!this.filterCountry) {
        return { operator: FILTERS_EQUAL, value: "AU,NZ" };
      }
      return this.filterCountry;
    },
    filterClients() {
      return this.crud.getFilter("TypeData");
    },
    valueClient() {
      if (!this.filterClients) {
        return { operator: FILTERS_EQUAL, value: null };
      }
      return this.filterClients;
    },
    filterShipDate() {
      return this.crud.getFilter("FromShipDate");
    },
    valueShipDate() {
      if (!this.filterShipDate)
        return [];
      return this.filterShipDate.value.split(",");
    }
  },
  created() {
    this.getCountries();
    this.crud.deleteFilter("IsInactive");
    this.setFilter("CountryCode", FILTERS_CONTAINS, "AU,NZ");
    this.setFilter("TypeData", FILTERS_CONTAINS, null);
  },
  methods: {
    setFilter(key, operator, value) {
      this.crud.setFilter(key, {
        operator,
        value
      });
    },
    goToEdit({ order, typeData, countryCode }) {
      this.$router.push({
        name: "EditExportOrdersData",
        params: {
          id: order
        },
        query: {
          typeData,
          countryCode
        }
      });
    },
    goToDetail({ order, typeData, countryCode }) {
      this.$router.push({
        name: "DetailsExportOrdersData",
        params: {
          id: order
        },
        query: {
          typeData,
          countryCode
        }
      });
    },
    openPullDataModal(source = 1) {
      this.$router.push({
        name: "PullData",
        query: {
          source
        }
      });
    },
    parseCurrency(value) {
      if (!value)
        return "";
      return currencyFormatter.format(value);
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format(FORMAT_DATE_PRIMARY);
    },
    getCountries() {
      this.axios.get(`${this.apiUrl}/export-order/common/countries`).then((res) => {
        let { data: countries } = res;
        countries.forEach((x) => this.countries.push(x));
      });
    },
    onFilterChange(key, value) {
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      if (key === "FromShipDate" && value) {
        this.setFilter(key, [FILTERS_GREATER_THAN_OR_EQUAL, FILTERS_LESS_THAN_OR_EQUAL], `${value.startDate},${value.endDate}`);
      } else {
        this.setFilter(key, FILTERS_CONTAINS, value);
      }
      this.crud.fetchList();
    },
    parseNumber(value) {
      if (!value)
        return "";
      return Math.round(value);
    },
    disabledDate(current) {
      return current && current > this.$moment().endOf("day");
    },
    async onExportOrdersClick() {
      this.isExportingOrders = true;
      try {
        const filterString = this.crud.getFilterQuery();
        const queryString$1 = this.crud.getQueryStrings();
        const params = queryString.parse(`${filterString}&${queryString$1}`);
        await this.downloadFile(`${this.apiUrl}/export-order/export-order-data/exports`, "text/csv;charset=utf-8;", params);
      } finally {
        this.isExportingOrders = false;
      }
    },
    onShowRiskRecordsToggle(isShown) {
      if (isShown) {
        this.crud.setQueryString("isRisk", "true");
      } else {
        this.crud.deleteQueryString("isRisk");
      }
      this.crud.setPagination({ page: 1 });
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "38be81cd", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListExportOrdersData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-tracking-status"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.clients",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-data",
      "resource-id-name": "id",
      "api-url": _vm.apiUrl,
      "edit-route": "/export-orders/export-orders-data/:id",
      "filter-route": "/export-orders/export-orders-data/advanced-search",
      "page": _vm.EXO_DATA
    }
  }, [_c("list-export-orders-data")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListExportOrdersData
  },
  data() {
    return {
      VIEW_PERMISSION,
      EXO_DATA,
      apiUrl,
      itemsMenu: [
        {
          key: "export-orders-data",
          title: "Export Orders Data",
          path: "/export-orders/export-orders-data"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
