const DATA_SOURCE_ID = {
  JDE: 1,
  BPCS: 2
};
const DATA_SOURCE_NAME = {
  [DATA_SOURCE_ID.JDE]: "JDE",
  [DATA_SOURCE_ID.BPCS]: "BPCS"
};
const JOB_STATUS = {
  QUEUE: 1,
  IN_PROGRESS: 2,
  SUCCESS: 3,
  FAULT: 4
};
const DATERANGES = [
  {
    id: 0,
    name: "Current Month",
    value: "CurrentMonth"
  },
  {
    id: 1,
    name: "Next Month",
    value: "NextMonth"
  }
];
const ROLES = {
  NONCORE_ADMIN: "nonCoreAdminEOR",
  EXO_ADMIN: "exportAdminEOR",
  EXO_OFFICER: "exportOfficerEOR"
};
const CLIENTS = {
  HEINZ: "heinz",
  CEREBOS: "cerebos"
};
export { CLIENTS as C, DATA_SOURCE_ID as D, JOB_STATUS as J, ROLES as R, DATA_SOURCE_NAME as a, DATERANGES as b };
